<template>
  <div>
    <v-list-item
      link
      @click="$emit('select-order', order)"
      three-line
    >
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-avatar
            v-on="on"
            v-bind="attrs"
            :color="orderColor"
          >
            <v-icon color="white">
              {{ orderIcon }}
            </v-icon>
          </v-list-item-avatar>
        </template>
        <span>
          {{ $t('Order balance') }} {{ orderBalance }}
        </span>
      </v-tooltip>
      <v-list-item-content>
        <v-list-item-title>
          {{ customerName }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ $moment(order.created).format('DD/MM/YYYY HH:mm') }} - {{ order.orderId }}{{ orderSequence ? ` - ${orderSequence}` : '' }} - {{ channelName }}</v-list-item-subtitle>
        <v-list-item-subtitle>{{ formattedAmount }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-if="order && order.locationVariation"
              v-on="on"
              v-bind="attrs"
              small
              label
              class="mb-1"
            >
              {{ order.locationVariation.label }}
            </v-chip>
          </template>
          <span>
            {{ $t('Table/Unit') }}
          </span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-if="orderCoupon"
              v-on="on"
              v-bind="attrs"
              small
              label
              outlined
            >
              {{ orderCouponText }}
            </v-chip>
          </template>
          <span>
            {{ $t('Coupon') }}
          </span>
        </v-tooltip>
      </v-list-item-action>
    </v-list-item>
    <div v-if="showLineItems === true">
      <template v-for="lineItem in order.lineItems">
        <v-list-item
          :key="'lineItem-' + lineItem.uuid"
          link
          :to="'/sales/orders/' + order.uuid"
        >
          <LineItemListItem :line-item="lineItem" />
        </v-list-item>
      </template>
    </div>
  </div>
</template>

<script>

  import LineItemListItem from "./lineItem/LineItemListItem.vue"

  export default {
    name: 'OrderListItem',
    props: {
      order: {
        type: Object, 
        default: () => null
      }
    },
    components: {
      LineItemListItem
    },
	  emits: ['select-order'],
    data: () => ({
      showLineItems: false
    }),
    computed: {
		orderSequence() {
			const sequence = this.order?.sequence ?? null
			return sequence !== '0' ? sequence : null
		},
		orderCouponText() {
			if(this.orderCoupon === null) {
				return ''
			}

			if(typeof this.orderCoupon.label === 'undefined' || this.orderCoupon.label === null) {
				return this.orderCoupon.code
			}

			return this.orderCoupon.label
		},
		orderCoupon() {
			if(typeof this.order === 'undefined' || this.order === null) {
				return null
			}
			if(typeof this.order.coupon === 'undefined' || this.order.coupon === null) {
				return null
			}
			return this.order.coupon
		},
		orderBalance() {
			if(typeof this.order === 'undefined' || this.order === null) {
				return this.$t('Not applicable')
			}
			if(typeof this.order.balance === 'undefined' || this.order.balance === null) {
				return this.$t('Not applicable')
			}
			return this.$store.getters.formatAmountByCurrency(this.order.balance)
		},
      formattedAmount() {
        if(typeof this.order !== 'undefined' && this.order !== null) {
          return this.$store.getters.formatAmountByCurrency(this.order.totalAmount)
        }
        return ''
      },
      canViewReceipt() {
        return this.order.balance !== null && this.order.balance.amount === 0
      },
      canMakePayment() {
        return this.order.balance !== null && this.order.balance.amount > 0
      },
      channelName() {

        if(this.order.channel !== null && this.order.channel.label) {
          return this.order.channel.label
        }

        return this.$t('Back-office')
      },
      customerName() {

        if(this.order.customer !== null && this.order.customer.address !== null && this.order.customer.address.name_line !== null) {
          return this.order.customer.address.name_line
        }

        return this.$t('anonymous')
      },
      orderIcon() {

        if(this.order.balance === null || this.order.balance.amount > 0) {
          return 'mdi-close'
        }

        return 'mdi-check'
      },
      orderColor() {

        if(this.order.balance === null || this.order.balance.amount > 0) {
          return 'orange'
        }

        return 'green'
      },
      channelLabel() {

        if(this.order.channel !== null && this.order.channel.label !== null) {
          return this.order.channel.label
        }

        return 'SalesCloud'
      }
    }
  }
</script>
