<template>
  <div class="pb-6">
    <v-row class="mx-2 mb-0 justify-space-around text-left align-baseline">
      <v-col
        cols="2"
        class="ma-0 pa-0 font-weight-bold"
      >
        <v-text-field
          dense
          type="number"
          :class="{'text-red': lineItem.deleted}"
          v-model="lineItem.quantity"
          :readonly="!editable || lineItem.deleted"
          :solo="!editable || lineItem.deleted"
          :flat="!editable || lineItem.deleted"
          :hide-details="!editable || lineItem.deleted"
        />
      </v-col>
      <v-col
        cols="3"
        class="align-self-start pb-0"
      >
        <div
          class="font-weight-bold"
          :style=" lineItem.deleted ? 'color: red' : '' "
        >
          {{ lineItem.label }}
        </div>
        <div
          v-if="lineItem.comment && false"
          :style="'opacity: 70%;' + (lineItem.deleted ? 'color: red' : '') "
          class="text-caption"
        >
          <b> {{ $t('comment') }}: </b>
          {{ lineItem.comment }}
        </div>
        <div
          v-if="Array.isArray(lineItem.variations) && lineItem.variations.length > 0 && false"
          :style="'opacity: 70%;' + (lineItem.deleted ? 'color: red' : '') "
          class="text-caption"
        >
          <b> {{ $t('variations') }}: </b>
          {{ lineItem.variations.map(v => v.label).join(', ') }}
        </div>
      </v-col>
      <v-col
        cols="2"
        class="ma-0 pa-0"
      >
        <v-text-field
          v-model="formattedPrice"
          :class="{'text-red': lineItem.deleted}"
          dense
          type="number"
          :readonly="!editable || lineItem.deleted"
          :solo="!editable || lineItem.deleted"
          :flat="!editable || lineItem.deleted"
          :hide-details="!editable || lineItem.deleted"
        />
      </v-col>
      <v-col
        cols="1"
        class="mx-3 pa-0"
      >
        <v-text-field
          v-model="discount"
          :min="0"
          :max="100"
          :class="{'text-red': lineItem.deleted}"
          dense
          :rules="[value => value <= 100 && value >= 0 || this.discountErrorText]"
          type="number"
          :readonly="!editable || lineItem.deleted"
          :solo="!editable || lineItem.deleted"
          :flat="!editable || lineItem.deleted"
          :hide-details="!editable || lineItem.deleted"
        >
          <template v-slot:append>
            <div :style="lineItem.deleted ? 'color: red' : '' ">
              %
            </div>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        cols="2"
        class="ma-0 pa-0 font-weight-bold"
      >
        <v-text-field
          v-model="totalPriceCalculated"
          :class="{'text-red': lineItem.deleted}"
          readonly
          solo
          flat
          hide-details
        >
          {{ totalPriceCalculated }}
        </v-text-field>
      </v-col>
      <v-col
        cols="1"
        class="ma-0 pa-0 "
      >
        <v-btn
          v-if="editable && !lineItem.deleted"
          icon
          x-small
          @click="openCommentDialog"
        >
          <v-icon>
            {{ lineItem.comment ? "mdi-comment-edit" : "mdi-comment-plus-outline" }}
          </v-icon>
        </v-btn>
        <v-btn
          v-if="editable && !lineItem.deleted"
          icon
          @click="toggleDeleteItem(true)"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
        <v-btn
          v-if="editable && lineItem.deleted"
          icon
          @click="toggleDeleteItem(false)"
        >
          <v-icon>
            mdi-undo
          </v-icon>
          {{ $t('undo') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="Array.isArray(lineItem.variations) && lineItem.variations.length > 0"
      :style="'opacity: 70%;' + (lineItem.deleted ? 'color: red' : '') "
      class="mx-2 mb-0 text-left align-baseline text-caption justify-space-around"
    >
      <v-col
        cols="2"
        class="py-0"
      />
      <v-col
        cols="auto"
        class="grow py-0 px-6"
        style="margin-left: 2px;"
      >
        <b> {{ $t('variations') }}: </b>
        {{ lineItem.variations.map(v => v.label).join(', ') }}
      </v-col>
    </v-row>
    <v-row
      v-if="lineItem.comment"
      :style="'opacity: 70%;' + (lineItem.deleted ? 'color: red' : '') "
      class="mx-2 mb-0 text-left align-baseline text-caption justify-space-around"
    >
      <v-col
        cols="2"
        class="py-0"
      />
      <v-col
        cols="auto"
        class="grow py-0 px-6"
        style="margin-left: 2px;"
      >
        <b> {{ $t('comment') }}: </b>
        {{ lineItem.comment }}
      </v-col>
    </v-row>
    <v-row
      v-if="appliedCoupon"
      style="opacity: 70%;"
      class="mx-2 mb-0 text-left align-baseline text-caption justify-space-around"
    >
      <v-col
        cols="2"
        class="py-0"
      />
      <v-col
        cols="auto"
        class="grow py-0 px-6"
        style="margin-left: 2px;"
      >
        <b> {{ $t('Coupon') }} </b>
        <span> {{ appliedCoupon.label }}</span>
        <b> {{ $t('applied') }} </b>
        <b class="text-lowercase"> {{ $t('with') }} {{ $t('code') }}</b>
        <span> {{ appliedCoupon.code }}</span>
      </v-col>
    </v-row>
    <TextAreaDialog
      v-if="showCommentDialog"
      :show-dialog="showCommentDialog"
      :title="$t('Comment')"
      :text-value="lineItem.comment"
      @close="closeCommentDialog"
      @updateValue="updateLineItemComment"
    />
  </div>
</template>

<script>
import TextAreaDialog from "@/components/common/TextAreaDialog"

export default {
    name: 'LineItemListItem',
	components: {
		TextAreaDialog
	},
	props: {
      lineItem: {
        type: Object,
        default: () => {}
      },
      editable: {
        type: Boolean,
        default: false
      },
		appliedCoupon: {
			type: Object,
			default: null
		}
    },
    data() {
      return {
        discount: 0,
        showActionsDialog: false,
        selectedOperation: null,
        formattedPrice: 0,
        showCommentDialog: false,
        lineItemObject: null
      }
    },
    created() {
		if(typeof this.lineItem !== 'undefined' && this.lineItem !== null) {
			try {
				this.lineItemObject = JSON.parse(JSON.stringify(this.lineItem))
			} catch (e) {
				// Do nothing
			}
			if(typeof this.lineItem.discountPercent !== 'undefined' && this.lineItem.discountPercent !== null) {
				this.discount = this.lineItem.discountPercent
			}
			if(typeof this.lineItem.unitPrice !== 'undefined' && this.lineItem.unitPrice !== null) {
				if(!isNaN(Number(this.lineItem.unitPrice.amount)) && this.lineItem.unitPrice.amount !== null) {
					this.formattedPrice = this.$store.getters.formatNumberByCurrency(this.lineItem.unitPrice)
				}
			}
		}
    },
    methods: {
      toggleDeleteItem(value) {
        this.lineItem.deleted = value
        // Vue has a reactivity issue where components don't update on object prop change in specific conditions (this.lineItem.deleted)
        // So I force the update here. Read more about it here: https://stackoverflow.com/questions/50159583/vue-component-prop-change-does-not-trigger-rerender -JMC
        this.$forceUpdate()
      },
      openCommentDialog() {
		this.showCommentDialog = true
      },
	    closeCommentDialog() {
			this.showCommentDialog = false
	    },
	    updateLineItemComment(value) {
            this.lineItem.comment = value
			this.showCommentDialog = false
	    },
    },
    computed: {
      discountErrorText() {
        return this.$t('discountError')
      },
      totalPriceCalculated() {
        let quantity = this.lineItem.quantity
        if(typeof quantity === 'string') {
          quantity = parseFloat(quantity)
        }
        let discount = this.discount
        if(typeof discount === 'string') {
          discount = parseFloat(discount)
        }
		if(isNaN(discount) || typeof discount === 'undefined' || discount === null) {
			discount = 0
		}
        let unitPrice = this.lineItem.unitPrice.amount
        if(typeof unitPrice === 'string') {
          unitPrice = parseFloat(unitPrice)
        }
        const price = {
          amount: ((unitPrice * quantity) * (1 - (discount / 100))),
          currency_code: this.lineItem.unitPrice.currency_code
        }
        return this.$store.getters.formatAmountByCurrency(price)
      },
    },
    /* These watchers are necessary due to a fault in vue to rerender the template when 
       properties are set in created() hook. Please change if you find a way to make it work
       without the watchers.
    */
    watch: {
      formattedPrice(newPrice) {
        this.lineItem.unitPrice.amount = this.$store.getters.unformatAmountByCurrency({
          amount: newPrice,
          currency_code: this.lineItem.unitPrice.currency_code
        })
      },
      discount(newDiscount) {
	      if(isNaN(Number(newDiscount)) || newDiscount === null) {
		      this.lineItem.discountPercent = 0
		      this.discount = 0
		      return
	      }
	      if(newDiscount <= 0) {
		      this.lineItem.discountPercent = 0
		      this.discount = 0
		      return
	      }
	      if(newDiscount >= 100) {
		      this.lineItem.discountPercent = 100
		      this.discount = 100
		      return
	      }

	      this.lineItem.discountPercent = Number(newDiscount)
      }
    }
}
</script>

<style>

.text-red input{
  color: #FF3B30 !important;
}

</style>